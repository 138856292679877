.otp-main {
    width: 100%;
    height: 100vh;
}

.react-confirm-alert-overlay {
  background-color: red; /* Replace with your desired background color */
}



.otp-container {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.resent-otp {
    display: flex;
    
    align-items: center;
    justify-content: center;
    gap: 8px;
   
}
.otp-input {
  display: flex;
  gap: 1rem  !important;
}
.resend{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4vw;
}
.resent-otp span{
  color: #0465F5;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.otp-verify-btn {
  background: var(--SOCIAL, linear-gradient(89deg, #820000 0.53%, #FF0505 99.5%), #E6E6E6);
  /* padding: 0.5625rem 1rem 0.625rem 1rem; */
  color: azure;
  border: none;
  border-radius: 5px;
  width: 12vw;
}

.otp-box-contain {
    width: 43.6875rem;
    display: flex;
    height: 25.5625rem;
    border-radius: 4px;
    flex-shrink: 0;
    background: #BAE2FF;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}
.otp-input-container {
    display: flex;
  }
  .otp-p {
    width: 83%;
    text-align: center;
}
  
  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  
  }
  .otp-header h2 {
    margin-bottom: 0;
  }
  .otp-p p {
    margin-top: 0;
  }
  .resent{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11.4783px;
  line-height: 17px;
  text-align: center;
  color: rgba(25, 21, 21, 0.95);
  }
  .resent-otp{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:1rem
  }
  .otp-countdown{
    color: #3551ef;
  }
  .sen-again {
    font-family: 'Poppins';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 9.38599px;
    line-height: 11px;
    /* border: 0.618184px solid #FFFFFF; */
    border-radius: 3.23137px;
    text-align: center;
    width: 69.28px;
    padding: 7px;
    color: #ffffff;
    BACKGROUND-COLOR: #ffd004;
    cursor: pointer;
}

  @media screen and (max-width: 420px) {
    .otp-box-contain {
        width: 100%;
    }
    .otp-container {
        width: auto;
        padding: 1rem;
    }
    .resent-otp {
      flex-direction: column;
    }
    
  }