.social-page-container{
    display: flex;
    width: 100%;
    background-color: rgb(81 107 177);
    height: auto;
    flex-direction: column;
}
.social-page-tp-con {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
}
.social-page-tp {
    display: flex;
    width: min(95vw ,100%);
    height: 15vh;
    justify-content: space-between;
    align-items: center;
  
}
.social-page-tp img {
    width: 75%;
}
.social-page-tp p {
color: #FFF;
font-family: 'Noto Serif';
font-size: 1rem;
text-align: center;
font-weight:500;
width: 260px;
padding:1rem;
border-radius: 2px;
background: linear-gradient(4deg, #820000 2.88%, #E66060 70.04%, #AE0707 96.06%), #E6E6E6;
}
.social-page-btm-con {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-page-btm {

    width: 100%;
    height: 85vh;
}
.tabs {
    display: flex;
    width: 100%;
    gap:1rem;
    margin-left: -5vw;
    margin-top: -5vh;
    align-items: center;
    justify-content: flex-end;
}
.tab{
    display: flex;
    gap:1rem;
    align-items: center;
    background: #868aa5;
    box-shadow: 0px 2.02564px 2.02564px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}
.Tabactive{
    background: linear-gradient(4deg, #820000 2.88%, #E66060 70.04%, #AE0707 96.06%), #E6E6E6;
   
}
:is(.youtube-box,.facebook-box ,.twitter-box){
    display: flex;
    width: 170px;
    padding: 0.25em .5em ;
    height: auto;
    align-items: center;
    justify-content: space-around;
}
:is(.youtube-box p,.facebook-box p ,.twitter-box p){
color: #FFF;
font-family: Poppins;
font-size: 14.154px;
font-style: normal;

line-height: normal;
text-transform: capitalize;
}
:is(.youtube-box img,.facebook-box img ,.twitter-box img){
 width: 26px;
 height: 26px;
}
.tab-content {
    width: 100%;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab-bg-main{
    width: 100vw;
    height: auto;
    background-image: url('../images/IHAF\ website\ background\ \(2\).png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-page-btm{
    display: flex;
    flex-direction: column;
    gap:2rem;
}
.Tab-content1{
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Tab-content1-tp{
     height: 15vh;
     display: flex;
     align-items: center;
     justify-content: center;
}
.Tab-content1-tp p{
    border-radius: 25.71px;
    background: #7EA2FF;
    color: #FFF;
    padding-inline: 2.5rem;
    padding-block: 0.5rem;
    text-align: center;
    font-family: Poppins;
    font-size: 12.998px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-shadow: 0px 2.99008px 2.99008px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 12px;
}
.Tab-content1-bt {
    height: auto;
    width: min(81vw ,100%);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 2vw;
}
.Tab-content1-bt::-webkit-scrollbar{
    width: 0;
}
.embeddedurl{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.TabContent1-content {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    /* justify-content: center; */
    height: 75vh;
}
.youtube {
    width: 64vw;
    height: 56vh;
}
@media screen and (max-width:912px){
    .social-page-tp p {
        font-size: 2.8vw;
        padding: 0.5rem;
    }
    .tab-content{
        height: auto;
    }
    .social-page-btm{
        height: auto;
    }
    .TabContent1-content{
        height: 45vh;
    }
    .Tab-content1-tp{
        height: 8vh;
    }
    .Tab-content1{
        height: auto;
    }
    .social-page-tp{
        height: auto;
    }
    .tabs{
        margin-top: -2.5vh;
    }
    .social-page-btm-con{
        height: auto;
        padding-block: 2.5em;
    }
    :is(.tab-bg,.social-page-btm){
        width: min(90vw ,100%);
    }
    .Tab-content1-bt{
        width: min(90vw ,100%);
    }
    
}
@media screen and (max-width:540px) {
    .social-page-tp img{
        width: 65%;
    }
    .tabs {
        flex-direction: row;
        width: 100vw;
        justify-content: center;
    }
   :is(.social-page-btm-con,.social-page-btm,.tab-content,.tab-bg,.Tab-content1){
    height: auto;
   }
    .Tab-content1-bt{
        overflow-y: unset;
        flex-direction: column;
        align-items: center;
        gap:2rem;
        height: auto;
    }
    .youtube{
        width:450px;
    }
    .Tab-content1-tp p{
        border-radius: 0;
    }
}
@media screen and (max-width:450px){
    .instagram{
        width: 325px;
    }
    .youtube{
        width:325px;
    }
    .tabs{
        flex-direction: column;
    }
    :is(.youtube-box,.facebook-box ,.twitter-box){
        width: 210px;
        padding: 0;
    }
    :is(.tab-bg,.social-page-btm) {
        width:100%;
    }
    .Tab-content1-tp{
        height: auto;
    }
    .Tab-content1-tp p {
        
        padding-inline: 1rem;
        padding-block: 0.5rem;
        width: 300px;
        font-size: 12.998px;
    }
    .social-page-tp img {
        width: 100%;
        padding: 0.5em;
        height: 16px;
    }
    .Tab-content1-tp p{
        border-radius: 0;
    }
    .TabContent1-content {
      
        align-items: center;
        justify-content: center;
        height: 50vh;
        gap: 12px;
    }
}