.eventdetails-main {
    width: 100%;
    height: 100vh;
}
.topic-event-details {
    width: 100%;
    height: 13.9375rem;
    background: #E9E9E9;
    display: flex;
    align-items: center;
    color: rgb(13, 2, 2);
    /* justify-content: flex-start; */
    gap: 10vw;
}
.wraps{
    overflow-y: scroll;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.wraps::-webkit-scrollbar{
    width: 0;
}
.event-details-logo img {
  
    width: 18.7875rem;
    height: 13.122rem;
}
span {
    font-weight: 400;
    padding-left: 0.25rem;
}
.images-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px; 
    justify-content: space-around; 
    justify-items: center; 
    align-items: center;
    padding: 10px;
    margin: 10px; 
    box-sizing: border-box;  
  }
  
  .images-wrap img {
    width: 40vw; 
    max-width: 100%; 
    height: auto;
  }

  
@media screen and (max-width :496px) {
    .images-wrap img {
        width: 100%;
    }
    .images-wrap {
       display: flex;
       flex-direction: column;
       width: auto;
       height: auto;
    }
    .topic-event-details {
        flex-direction: column;
        height: auto;
    }
    .event-details-logo img {
        width: auto;
        height: 8.122rem;
    }
    
}
@media screen and (max-width :696px) {
    .images-wrap img {
        width: 100%;
    }
}