 
.navbar{
  width: 100%;
  height: 12vh;
  background: linear-gradient(270deg, #FFF -27.12%, #1B4AC4 -0.22%, #042069 23.08%, #01154B 33.23%);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.navbar-logo{
display: flex ;
gap:1em;
width: 40%;
align-items: center;
}
:is(.navbar-logo p ,.navbar-logo span){
  border-radius: 1.375rem;
  font-family: 'Poppins';
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem;
 
  font-style: normal;
  line-height: normal;
}
.navbar-logo p{
  border-radius: 1.375rem;
  background: #FFB800;
  color: #000;
  font-weight: 400;
  width: 7.5em;
}
 
.navbar-logo span{
 
  font-weight: 500;
  background: linear-gradient(89deg, #820000 0.53%, #FF0505 99.5%), #E6E6E6;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.navbar-logo img{
  width: 80px;
  height: auto;
  padding: 5px;
}
.navbar-links{
  display: flex;
  align-items: center;
  width:80%;
  gap:1rem;
  justify-content: flex-end;
}
.navbarsocial-M {
display: flex;
align-items: center;
justify-content: space-evenly;
gap:1rem;
}
.navbar-links ul{
 display: flex;
 list-style: none;
 gap:2rem;
 align-items: center;
}
:is(.Navbar-link-tamil ,.Navbar-link-english){
color: #FFF;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration: none;
text-transform: uppercase;
background: right / 0% 100% no-repeat linear-gradient(#2a2a2a, #2a2a2a);
position: relative;
 
}
.Navbar-link-tamil{
  font-size: 15.045px;
}
.Navbar-link-tamil:hover{
  font-size: 18.045px;
}
.Navbar-link-english{
  font-size: 18.045px;
}
.Navbar-link-english:hover{
  font-size: 21.045px;
}
 
.navbar-links ul li a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0%; /* Initially set to 0 width */
  height: 2px; /* Border height */
  background-color: #ffffff; /* Border color */
  transition: width 400ms; /* Transition for width to create the effect */
}
.navbar-links ul li a:hover::after{
  width: 100%;
  font-size: 24.045px;
}
 
 
.icons{
  color: #FFF;
}
.navbar-social{
  display: flex;
  gap:1.2em;
  align-items: center;
}
/* .navbar-social a{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #6c6ee7;
} */
.navbar-toggle button{
  display: none;
}
:is(.navbar-login button  ,.Navbar-login-ta button ){
display: flex;
height:30.648px;
padding: 1rem;
justify-content: center;
align-items: center;
border: none;
border-radius: 5.282px;
background: #356DFF;
cursor: pointer;
}
.hovdon{
 font-family: 'Poppins', sans-serif;
  cursor: pointer;
 color:white;
  transition: background-color 0.3s ease;
 
}
 
.hovdon:hover{
 
  color: #ffffff;
 padding: 8px;
  background-color: #e5a603;
  border-radius: 5px;
 
}
 
.hovjoin{
 
  transition: background-color 0.3s ease;
 
  text-decoration:none ;
 
 
 
  padding:0.5em;
 
  background:10rem;
 
  background-color:white;
 
  text-align:center;
 
  margin:'0';
 
  border-radius: 2rem;
 
}
.toggle{
  cursor: pointer;
}
.hovjoin:hover{
 
  color: #ffffff;
 
  background-color: #df0505;
 
}
 
.hov:hover{
 
 
 
  background-color: #4e970a;
 
}
:is(.Navbar-login-tamil ,.Navbar-login-english){
  color: #FFF;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.Navbar-login-tamil{
  font-size: 12.96px;
}
.Navbar-login-english{
  font-size: 16.96px;
}
:is(.navbar-login ,.translate-btn-1 ,.navbar-content) {
  padding-inline-end: 2rem;
  cursor: pointer;
}
.translate-btn{
  display: none;
}
.Popcontainer{
  z-index: 4;
  right: 0;
  width: 100%;
  display: flex;
  top:10vh;
  position: absolute;
  justify-content: flex-end;
}
.profile {
  border-radius: 40vw;
}
.Pop-page{
  width: 239px;
  height: auto;
  display: flex;
  background: linear-gradient(270deg, #FFF -27.12%, #1B4AC4 -0.22%, #042069 23.08%, #01154B 33.23%);
  padding: 1rem;
  flex-direction: column;
}
.profile-icon{
  width: 100%;
  height: 15vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-icon img{
  width: 80px;
  height: 80px;
}
:is(.referal-code ,.feedback-pop ,.logout-pop){
  display: flex;
  height: 10vh;
  gap:1.5rem;
  align-items: center;
  /* margin-left: 15px; */
}
:is(.referal-code p,.feedback-pop p,.logout-pop p){
  color: white;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
:is(.referal-code img,.feedback-pop img,.logout-pop img){
width: 30px;
height: 30px;
}
.referal-code select{
 width: 300px;
 padding: 4px;
 border: none;
}
.paste-button {
  position: relative;
  display: block;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
 
.button {
  background-color: #ffffff;
  color: #212121;
  font-size: 15px;
  padding: 9px;
  display: flex;
  border: 2px solid transparent;
  cursor: pointer;
  align-items: center;
}
.closeimage-popup{
  width: 24px;
  height: 20px;
  color: white;
  cursor: pointer;
  text-align: center;
  border: 2px solid white;
}
 
.dropdown-content {
  display: none;
  width: 150px;
  position: absolute;
  z-index: 1;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  background-color: #007aff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.popup-image-close{
  width: 100%;
  display: flex;
  justify-content: end;
}
 
.dropdown-content a {
  color: #ffffff;
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  transition: 0.1s;
}
 
 
 
.dropdown-content a:focus {
  background-color: #212121;
  color: #ffffff;
}
 
 
.paste-button:hover .dropdown-content {
  display: block;
}
:is(.translate-btn button ,.translate-btn-1 button){
  background-color: transparent;
  border: none;
}
 
.toggle-container {
  position: relative;
 
  width: 85px;
  text-align: center;
  padding-block: 0.5rem;
  pointer-events: none;
  user-select: none;
  display: inline-block;
}
 
.toggle-container input {
  opacity: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
}
 
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--SOCIAL, linear-gradient(89deg, #820000 0.53%, #FF0505 99.5%), #E6E6E6);
  transition: 0.4s;
  pointer-events: none;
}
 
.slider::before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 15px;
  bottom: 6px;
  background-color: #ffffff;
  transition: 0.2s;
  pointer-events: none;
}
.slider::after {
  position: absolute;
  content: "ENG";
  height: 24px;
  width: 14px;
  right: 30px;
  bottom: 7.5px;
  transition: 0.2s;
  color: #fff;
  pointer-events: none;
}
.Navbar-login-ta {
  padding-right: 1rem;
  cursor: pointer;
}
input:checked + .slider {
  background-color: #2196f3;
}
 
input:checked + .slider:before {
  transform: translateX(40px);
}
 
input:checked + .slider:after {
  content: "தமிழ்";
  left: 8px;
  bottom: 8px;
}
 
.slider.round {
  border-radius: 34px;
}
 
.slider.round:before {
  border-radius: 50%;
}
.navbar-donation{
  display: none;
}
.nav-don{
  display: flex;
  gap: 1rem;
  align-items: center;
}
@media screen and (max-width:1280px) {
.navbar-links ul{
  padding-inline-start: 0;
}
:is(.navbar-logo p ,.navbar-logo span){
  font-size: 0.8em;
}
.navbar-links{
  gap:0.8em;
}
:is(.navbar-logo p ,.navbar-logo span){
  margin-inline: 1em;
}
:is(.navbar-login ,.translate-btn-1 ,.navbar-content){
  padding-inline-end: 1em;
  cursor: pointer;
}
}
 
@media screen and (max-width:1024px) {
  :is(.navbar-login ,.translate-btn-1 ,.navbar-content){
      padding-inline-end: 0;
      cursor: pointer;
  }
  .navbar-logo img{
    width: 60px;
    height: 60px;
  }
  .navbar-logo{
    gap: 0;
    width: auto;
  }
  :is(.navbar-logo p ,.navbar-logo span){
    font-size: 0.7em;
  }
}
 
@media screen and (max-width:998px) {
  .navbar-toggle button{
      display: flex;
      margin-right: 2rem;
  }
  .Popcontainer{
    left: -137px;
  }
  .Pop-page{
    width: 239px;
  }
  .navbar{
  justify-content: space-between;
  }
  .navbar-links{
      display: none;
  }
  :is(.navbar-logo p ,.navbar-logo span){
    font-size: 0.8rem;
  }
  .translate-btn{
      display: block;
      margin-left: 25rem;
  }
  .navbar-logo img{
    width: 80px;
    height: 80px;
}
  .navbar-links.active{
      position: absolute;
      flex-direction: column;
      align-items: flex-start;
      width: min(60vw ,100%);
      display: flex;
      background: linear-gradient(4deg, #820000 2.88%, #E66060 70.04%, #AE0707 96.06%), #E6E6E6;
      height: 88vh;
      gap: 2em;
      z-index: 4;
      top: 12vh;
      right: 0;
      justify-content: flex-start;
  }
  :is(.Navbar-login-english ,.Navbar-link-english,.Navbar-link-tamil ,.Navbar-login-tamil) {
    font-size: 24.045px;
}
:is(.navbar-login button ,.Navbar-login-ta button){
  padding: 2rem;
  margin-left: 20px;
  cursor: pointer;
}
.css-1shopag-MuiSvgIcon-root{
  height: 2em !important;
  width: 2em !important;
}
  .translate-btn-1 {
     display: none;
  }
  .navbar-links ul{
     flex-direction: column;
     width: 100%;
     align-items: flex-start;
     padding-inline-start: 20px;
  }
  :is(.navbar-login ,.navbar-social){
      padding-inline-start: 20px;
      cursor: pointer;
  }
}
@media screen and (max-width:600px) {
.navbar{
  width: auto;
  justify-content: space-between;
}
:is(.navbar-logo p ,.navbar-logo span) {
  font-size: .65em;
}
:is(.Navbar-login-english ,.Navbar-link-english,.Navbar-link-tamil ,.Navbar-login-tamil) {
font-size: 19.045px;
}
.css-1shopag-MuiSvgIcon-root{
height: 1.3em !important;
width: 1.3em !important;
}
:is(.navbar-login button ,.Navbar-login-ta button){
padding: 1rem 2rem;
cursor: pointer;
}
.navbar-donation.active {
display: flex;
flex-direction: column;
gap:1rem;
position: absolute;
top: 40vh;
padding-inline-start: 20px;
}
.navbar-donation.active p{
border-radius: 1.375rem;
background: #FFB800;
color: #000;
font-weight: 400;
width: 7.5em;
padding: 0.5em;
}
:is(.navbar-donation.active p ,.navbar-donation.active span) {
border-radius: 1.375rem;
font-family: 'Poppins';
font-size: 1rem;
text-align: center;
padding: 0.5rem;
font-style: normal;
line-height: normal;
}
.navbar-donation.active span{
   font-weight: 500;
  background: linear-gradient(89deg, #820000 0.53%, #FF0505 99.5%), #E6E6E6;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.nav-don{
display: none;
}
.translate-btn{
display: block;
margin-left: 16rem;
}
.Popcontainer{
  left: -13px;
}
.navbarsocial-M{
  flex-direction: column;
  gap:1em;
  align-items: flex-start;

}
}
@media screen and (max-width:420px){
  .translate-btn{
    display: block;
    margin-left: 8.5rem;
    }
    .Popcontainer{
      left: -37px;
    }
    .navbar-donation.active {
      top:42.5vh;
    }
}