.intro-slide-content-quates span {
    display: inline-block;
    opacity: 0;
    padding-left: 0;
    font-weight: 600;
    font-family: Crimson Text;
    filter: blur(4px);
    margin-left: 0.2em;
    
  }
  :is(.quates-card1-tamil,.quates-card1-english){
    line-height: 48px;
    width: 680px;
    padding: 0.5px;
}
.quates-card1-tamil span{
    font-size: 24.05px;
}
:is(.quates-card1-english span){
    font-size: 32px;
}
  .intro-slide-content-quates span:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(3) {
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(4) {
    animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(5) {
    animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(6) {
    animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(7) {
    animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(8) {
    animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(9) {
    animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(10) {
    animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(11) {
    animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(12) {
    animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(13) {
    animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(14) {
    animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(15) {
    animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(16) {
    animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(17) {
    animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .intro-slide-content-quates span:nth-child(18) {
    animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  .intro-slide-content-quates span:nth-child(19) {
    animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
    
  }
.Page-1{
    width: 100%;
    height:100vh;
    display: flex;
    flex-direction: column;
}
:is(.intro-slide-1,.intro-slide-2,.intro-slide-3,.intro-slide-4){
    width: 100%;
    height: 88vh;
    background-size: cover;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.intro-slide-1{
    background-image:url('../images/MicrosoftTeams-image\ \(18\).png');
}
.intro-slide-2{
    background-image:url('../images/MicrosoftTeams-image-2.png'); 
}
.intro-slide-3{
    background-image:url('../images/deks2.png' ); 
    width: 20vw;
}

@media screen and (max-width:550px) {
  .intro-slide-1{
    object-fit: cover;
    object-position: 0%;
    background-image:url('../images/img2.png');
}
}

@media screen and (max-width:550px) {
  .intro-slide-2{
    object-position: 0%;
    background-image:url('../images/img3.png');
}
}
@media screen and (max-width:550px) {
  .intro-slide-3{
    background-image:url('../images/img1.png');
}
}

.intro-slide-4{
  background-color: #00003B;
}

.intro-slide-content{
    display: flex;
}
.intro-slide-content-right{
    width: 100%;
    display: flex;
    height: 88vh;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    color:white;
}
.intro-slide-content-left{
    width: 100%;
    height: 88vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.intro-slide-content-right-btm img{
    object-fit: cover;
    height: 68vh;
}
:is(.intro-slide-content-right-top-tamil ,.intro-slide-content-right-top){
  width: 91%;
    height: 10vh;
    margin-top: 1rem;
    background: #F4F4F4;
    display: flex;
    align-items: center;
    gap:1rem;
}

:is(.intro-slide-content-right-top p ,.intro-slide-content-right-top-tamil p){
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 30.064px;
    padding-block: 5px;
    margin: 0;
    font-style: normal;
    width: 200px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 4.66px;
    background: var(--BLUE, #00003B);

}
:is(.intro-slide-content-right-top-tamil span ,.intro-slide-content-right-top span){
color: #000;
text-align: center;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
}
.intro-slide-content-right-top-tamil span{
    font-size: 12.405px;
}
.intro-slide-content-right-top span{
    font-size: 12.405px;
} 
:is(.intro-slide-content-quates ,.intro-slide-content-quates-1){
    display: flex;
    position: absolute;
    margin-right: 15rem;
    margin-top: 5em;
    flex-direction: column;
}

:is(.Quates-tamil,.Quates-english){
  text-align: center;
  font-family: Crimson Text;
  font-style: italic;
  font-weight: 600;
  width: 651.089px;
  line-height: normal;
}
.Quates-tamil{
  font-size: 18.405px;
}
.Quates-english{
  font-size: 24.405px;
}
.intro-slide-content-quates p{
color: #ffffff;

}
.intro-slide-content-quates-1 p{
    color: #ffe5e5;
    }
.Quates-img-1 {
    display: flex;
    width: 100%;
   justify-content: flex-start;
}
:is(.Quates-img-2 img,.Quates-img-1 img){
    width: 300.377px;
    height: 30.24px;

}
.Quates-img-2 {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

@media screen and (min-width:1279px) {
    .intro-slide-content-right-top p{
        padding-block: 10px;
    }
} 

.card-container {
  width: 100%; /* 100% * number of cards (3 in this case) */
  height: 88vh;
  position: relative;
  overflow: hidden;
}

:is(.intro-slide-1,.intro-slide-2,.intro-slide-3 ){
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 1s ease-in-out;
}

@keyframes slideRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.actived {
  transform: translateX(0);
  z-index: 3;
  animation: slideRightToLeft 1s linear ; 
}
@media screen and (max-width: 1160px) {
  :is(.quates-card1-tamil,.quates-card1-english){
    width: 47vw;
  }
}
@media screen and (max-width: 765px) {
  .intro-slide-content-right{
    width: 27%;
  }

:is(.intro-slide-content-quates p,.intro-slide-content-quates-1 p) {
  width: 80vw ;
}
}

@media screen and (max-width:998px){
    :is(.intro-slide-content-quates ,.intro-slide-content-quates-1){
      margin-right: 12em;
      margin-top: 0em;
    }
    .intro-slide-content-quates span {
      font-weight: 800;
    }
    .intro-slide-content-right-btm img{
        height: 55vh;
        
    }
    :is(.intro-slide-content-right-top-tamil ,.intro-slide-content-right-top){
        height: auto;
        padding-block: 0.5rem;
        width: 73vw;
    }
    :is(.intro-slide-content-quates p,.intro-slide-content-quates-1 p){
     
        width: 44vw;
        font-weight: 500;
        letter-spacing: .5px;
    }
    .Quates-tamil{
      font-size: 28.405px;
    }
    .Quates-english{
      font-size: 34.405px;
    }
   
}
@media screen and (max-width:540px){
    .intro-slide-content-right-btm img{
        height: 40vh;
    }
    :is(.intro-slide-content-quates ,.intro-slide-content-quates-1) {
      margin-right: 7em;
        margin-top: -5em;
    }
   
    .intro-slide-content-quates span  {
    
          line-height: 3vh;
      font-weight: 800;
    }
    :is(.intro-slide-content-quates p,.intro-slide-content-quates-1 p){  
    width: 345px;
    }
    :is(.intro-slide-content-right-top-tamil ,.intro-slide-content-right-top){
      width: 87vw;
    }
}
@media screen and (max-width:480px){

    .navbar-logo img {
        object-fit: contain;
        height: 60px;
}
.intro-slide-content-quates span {
  font-weight: 600;
  
}
    .intro-slide-content-right-btm img{
        display: none;
    }
   
    :is(.intro-slide-content-quates ,.intro-slide-content-quates-1) {
        margin-right: 8em;
        margin-top: -14em;

    }
    :is(.intro-slide-content-quates p,.intro-slide-content-quates-1 p) {
      font-weight: 600;
    
    }
    :is(.Quates-img-2 img,.Quates-img-1 img){
      width: 175px;
      height: 19.24px;
    }

    :is(.intro-slide-content-right-top-tamil ,.intro-slide-content-right-top) {
      width: 91vw;
    height: 7vh;
  }

    :is(.intro-slide-content-right-top-tamil ,.intro-slide-content-right-top) {
      margin-top: 1rem;
    
  }
  .intro-slide-content-right-top span {
   
    font-weight: 500;
}
.Quates-tamil{
  font-size: 18.405px;
}
.Quates-english{
  font-size: 34.405px;
}



}

@media screen and (max-width: 331px) {
   
  :is(.intro-slide-content-right-top p ,.intro-slide-content-right-top-tamil p) {
    font-size: 26.064px;
    width: auto;
}
:is(.intro-slide-content-quates p,.intro-slide-content-quates-1 p) {
  width: 91vw !important;
}

}


