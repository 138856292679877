.footer-image1{
    width: 149.199px;
    height: 141.269px;
}
.Footer-container{
    background: #000;
}
.footer-home{
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
}
.footer-home-tamil{
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
}
.footer-text-Addtress-head{
    color: #FFF;
text-align: start;
font-family: Poppins;
font-size: 19.732px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.footer-text-Addtress {
    color: #FFF;
    font-family: Poppins;
    font-size: 17.539px;
    font-style: normal;
    text-align: start;
    /* width: 36vh; */
    font-weight: 400;
    line-height: normal;
}
.footer-text-Addtress-tamil{
    color: #FFF;
    font-family: Poppins;
    font-size: 0.8rem;
    font-style: normal;
    text-align: start;
    /* width: 36vh; */
    font-weight: 400;
    line-height: normal;
}
.Footer-integral{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 1.5rem;
font-style: normal;
line-height: 170.5%; 
letter-spacing: 2.963px;
text-transform: uppercase;
}
.Footer-integral-tamil{
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    line-height: 170.5%;
    letter-spacing: 2.963px;
    text-transform: uppercase;
}
.Footer-copy-text{
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16.009px;
    padding-right: 10vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Footer-container-main {
    display: flex;
    width: 90vw;
    padding: 1rem;
    justify-content: space-between;
}
.footer-social {
    display: flex;
    align-items: center;
    width: 67vw;
    justify-content: space-between;
    height: 20vh;
    flex-direction: row;
}
.footer-text-address-container{
    width: 33vw;
    padding: 1em;
    height: auto;
    background: rgba(255, 255, 255, 0.21);

}
:is(.foot-bt-content-2,.foot-bt-content-1 ){
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.footer-join-us {
    display: flex;
    gap: 5rem;
    height: auto;
    justify-content: space-between;
}
.footer-logo {
    padding-left: 2rem;
    padding-top: 3rem;
}
.footer-copy-rights {
    height: auto;
    width: 71vw;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    padding-bottom: 2rem;
    flex-direction: column;
    display: flex;
}

@media screen and (max-width:1224px) {
    .footer-text-Addtress {
        color: #FFF;
        font-family: Poppins;
        font-size: 17.539px;
        font-style: normal;
        text-align: start;
        width: auto;
        font-weight: 400;
        line-height: normal;
    }

    .Footer-container-main {
        display: flex;
        flex-direction: column;
        width: auto;
        align-items: center;
        padding: 1rem;
        justify-content: space-between;
    }
    .footer-copy-rights {
        height: auto;
        width: auto;
        gap: 1rem;
        padding: 1rem;
        padding-bottom: 2rem;
        flex-direction: column;
        display: flex;
    }
    .footer-social {
        display: flex;
        align-items: center;
        justify-content: center;
         width: 80vw;
         gap:4rem;
        height: auto;
        flex-direction: row;
    }
    .footer-text-address-container {
        width: 38vw;
        /* padding-left: 2rem; */
        font-size: 10px;
        /* padding-right: 1rem; */
        height: auto;
        background: rgba(255, 255, 255, 0.21);  
    }
    .footer-text-Addtress {
        color: #FFF;
        font-family: Poppins;
        font-size: 17.539px;
        font-style: normal;
        text-align: start;
        /* width: 15rem; */
        font-weight: 400;
        line-height: normal;
    }
    .footer-text-Addtress-tamil{
        color: #FFF;
        font-family: Poppins;
        font-size: 0.8rem;
        font-style: normal;
        text-align: start;
        width: auto;
        font-weight: 400;
        line-height: normal;
    }
    .Footer-copy-text {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 16.009px;
        padding-right: 5vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .footer-home {
        width: 25vw;
    }
    .footer-logo {
        padding-left: 0rem;
        padding-top: 1rem;
    }
}
@media screen and (max-width: 820px) {
    .footer-join-us{
        gap: 2em;
    }
    .footer-text-address-container{
        width: 45vw;
    }
    .footer-social{
        gap: 1em;
    }
}

@media screen and (max-width: 540px) {
    .Footer-integral {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 170.5%;
        letter-spacing: 2.963px;
        text-transform: uppercase;
    }

    .footer-social {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: auto;
        flex-direction: column;
    }
    .footer-text-address-container {
        width: auto;
        padding-left: 2rem;
        padding-right: 1rem;
        height: auto;
        background: rgba(255, 255, 255, 0.21);
    }
    .footer-home {
        width: 24vw;
    }
    .footer-join-us{
        gap: 30vw;
    }
}

@media screen and (max-width:450px) {
    :is(.foot-bt-content-2,.foot-bt-content-1 ){
        align-items: center;
    }
    .footer-join-us {
        gap: 15vw;
    }
}