
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Press+Start+2P&display=swap');

.gallery-heading {
    color: #000;
    font-family: Poppins;
    font-size: 36px;
    margin: 0;
    font-style: normal;
    font-weight: 400;
}
.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gallery-head {
    height: 12vh;
        justify-content: center;
    align-items: center;
    display: flex;
}
.gallery-body {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  /* border: 1px solid #000; */
  background: #8cacd5;
  width: 54vw;
  height: 9vh;
  align-items: center;
}
.image-text {
  color: #274926;
  font-family: Poppins;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  width: 11vw;
  height: 4vh;
  background-color: #ffffff;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b9f8c7;
}
.sub-main {
  display: flex;
  flex-direction: column;
}
.fav-text {
  color: #333;
  font-family: Poppins;
  font-size: 15px;
  background-color: #ffffff;
  border: 1px #ffffff  solid;
  font-style: normal;
  font-weight: 400;
  width: 12vw;
  height: 5vh;
  
}

.images-gal {
    width: 323px;
    border-radius: 15px;
    height: 341px;
}
.modal-overlay3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0,0,0, 0.4);
  justify-content: center;
  align-items: center;
}
.gallery-images-container{
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    justify-content: center;
    flex-wrap: wrap;
}
.image-text-cont {
  color: #fff;
  font-family: Poppins;
  cursor: pointer;
  font-size: 16px;
  /* font-style: normal; */
  font-weight: 400;
}
.fav-text-C {
  color: #424242;
  font-family: Poppins;
  font-size: 15px;
  background-color: #d1e2f7;
  border: none;
  font-style: normal;
  font-weight: 400;
  width: 12vw;
  height: 5vh;
  border-radius: 0.5vw;
}
.videos-container{
  display: flex;
  gap: 2rem;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}

.video-item{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;

}
.play-btn {
    color: #FFF;
    cursor: pointer;
    border-radius: 63.791px;
    background: linear-gradient(284deg, #B8C2DA -47.47%, #134FE9 42.98%, #4F0909 154.35%);
    font-family: 'Press Start 2P';
    font-size: 20.535px;
    text-align: center;
    gap: 19px;
    align-items: center;
    display: flex;
    border: none;
    padding: 14.721px 28.215px 15.948px 35.576px;
    font-style: normal;
    font-weight: 400;
}
.dfgbsdfgf{
    width: 100%;
}
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  .gallery-fav-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 23vh;
    justify-content: space-evenly;
}
  .popup-content {
    text-align: center;
  }
  
  .close-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    right: 20px;
    top: 45px;
    border: 3px solid #FF1F1F;
    margin-top: 10px;
    position: absolute;
    cursor: pointer;
  }
  .video-control{
    width: 764px;
    position: relative;
    height: 440px;
  }
  .gallery-video{
    height: 88vh;
    overflow-x: hidden;
    width: 100%;
    overflow-y: scroll;
  }
  .favorate-image-C {
    width: 35vw;
    /* padding: 1.2rem; */
    display: flex;
    align-items: center;
    gap: -1rem;
    border-radius: 2px;
    background: #d1e2f7;
    height: 7vh;
    justify-content: space-around;
}
.fav-main {
  display: flex;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
}
  .video-galry{
    width: 320px;
    height: 200px;
  }
  /* .sdfghjkk{
   
    
      color: #FFF;
      font-family: Poppins;
      font-size: 19px;
      background-color: #4b4b4b;
      border: 2px;
      font-style: normal;
      font-weight: 400;
      width: 12vw;
      height: 5vh;
      border-radius: 1.5vw;
  
   
  } */
  .favorate-image-container{
    display: flex;
  }
  .favorite-icon {
    position: absolute;
}

  @media screen and (max-width: 880px) {
    .video-item{
        flex-direction: column;
    }
    .video-image{
       width: 83vw;
    }
    .gallery-body{
      border-radius: 1.0625rem;
      width: 85vw;
      height: 8vh;
    }
    .image-text{
      width: 18vw;
      height: 5vh;
    }
    .favorate-image-C{
      width: 48vw;
      border-radius: 1.0625rem;
      height: 8vh;
    }
    .fav-text{
      width: 16vw;
    }
  }
  @media screen and (max-width:540px){
    .gallery-body {
      width: 90vw;
      height: 10vh;
  }
  .image-text {
    width: 21vw;
    padding: 0.25em;
}
.favorate-image-C{
  width: 64vw;
}
.fav-text {
  width: 21vw;
}
.video-control {
  width: 500px;
  position: relative;
  background-color: black;
  height: 400px;
}
.close-btn{
  border: 3px solid #9a1212;
  margin-top: 18px;
}
  }
  @media screen and (max-width:450px){
    .gallery-body{
     flex-direction: column;
     height: auto;
    }
    .image-text {
      width: 48vw;
  }
  .favorate-image-C{
    width: 90vw;
  }
  .fav-text {
    width: 32vw;
}
.video-control {
  width: 385px;
  height: 400px;
}

  }