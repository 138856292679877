/* Define the custom font size for Tamil text */
.tamil20-font5{
    font-size: 20px;
  }
  .tamil18-font5{
    font-size: 18px;
  }
  .tamil30-font5{
    font-size: 30px;
  }
  .tamil12-font5{
    font-size: 12px;
  }
.tamil-font {
    font-size: 12px; /* You can adjust the font size as needed */
    /* You can also add any other CSS properties for Tamil text here */
}
/* .title-5 p{

} */
.container-5{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-block-start: 4vh;
    
}
.part1-5{

    width: 100%;
    height: auto;
    display: flex;
    overflow-x: hidden;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
}
.part1-5::-webkit-scrollbar{
 width: 0;
}
.part1-5 p {
    display: flex;
    width: 20vw;
    height: 9vh;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 2px;
    font-size: 1rem;
    background: linear-gradient(4deg, #820000 2.88%, #E66060 70.04%, #AE0707 96.06%), #E6E6E6;
    margin-inline-start: 2vw;
}

.part1-5 img{
    width: 77vw;
    height: auto;


}
.part2-5{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:2.5em;
    flex-direction: row;
    width: 100%;
    height: 80vh;
}
.left-5{
    width: 50vw;
    height:85vh ;
    display: flex;
    align-items: center;
    justify-content: center;

  

}
.left-5 img{
    width: 50vw;
    height: auto;
}
.right-5 {
    width: 38vw;
    height: 58vh;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    border-radius: 0.4705rem 0.4705rem 7.1785rem 0.4705rem;
    background-image: url("../Assets/Frame\ 45.png");
    margin-top: 8vh;
}
.right-5 p{
    width: 34vw;
    font-size: 1.4vw;
    padding-inline-start: 5vw;
    padding-inline-end: 5vw;
    margin-block-start: 7vh;
    color: #FFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.right-5 button{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 18vw;
    height: 7vh;
    justify-content: center;
    padding: 15px 44px 15px 44px;
    border-radius: 0.37375rem;
    background: linear-gradient(270deg, #820000 1.91%, #FF0505 99.81%), #E6E6E6;
    border: none;
    cursor: pointer;
    outline: none;
    color: white;
    justify-content: space-around;
    margin-left: 0%;
    font-size: 1.25vw;
    margin-top: 5vh;
}
.right-5 img{
    width: 2.5vw;
    height: auto;
}



@media screen and (max-width: 915px) {
    .left-5 {
        height: auto;
        /* margin: 0; */
    }
    .part2-5 {
        align-items: center;
        padding-bottom: 2rem;
        flex-direction: column;
        height: 100vh;
    }
    .right-5 {
        height: 20vh;
        margin: 0;
    }
    .container-5 {
        height: auto;
    }
    .right-5 button {
        margin: 0;
        height: 6vh;
       
    }
    .part1-5 p {
        height: 5vh;
       
    }
    .right-5 p {
        margin: 0;
        width: 53vw;
    }
    .right-5 {
        align-items: center;
        padding: 0vw 6vw 2vw 6vw;
        justify-content: center;
        gap: 38px;
        width: 55vw;
        height: 45vh;
    }
    .left-5 img {
        width: 75vw;
        height: auto;
    }
    
}
@media screen and (max-width: 820px) {
    .part1-5 img{
        width: 62vw;
    }
    .part1-5 p{
        width: 50vw;
    }
    .right-5{
        width: 70vw;
        height: 60vh;
        border-radius: 0.4705rem 0.4705rem 4.9785rem 0.4705rem;
    }

    .right-5 p{
        font-size: 14px;
    }
    .right-5 button{
        width: auto;
        font-size: 3vw;
    }
    .right-5 img{
        width: 5.5vw;
    }
}
@media screen and (max-width: 476px) {
    .part1-5 img{
      width: 53vw;
      height: 1em;
    }
    .part1-5 p {
        width: 41vw;
        height: 4vh;
        font-size: 2.8vw;
    }
    .left-5 img{
        width: 90vw;
       
    }
    .right-5 {
        width: 80vw;
        height: 50vh;
        border-radius: 0.4705rem 0.4705rem 3.9785rem 0.4705rem
    }
    .right-5 p{
        font-size: 1rem;
        width: 80vw;
    }
    .right-5 button {
        width: 65vw;
        height: 5vh;
        font-size: 1rem;
    }
    .right-5 img{
        width: 5.5vw;
    }

}
