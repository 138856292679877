@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:ital,wght@0,200;0,400;1,300&family=Song+Myung&family=Sora:wght@300;400&display=swap');


.page8-container {
   background-image: url("../Assets/MicrosoftTeams-image\ \(24\).png");
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
    width: 100%;
    border: none;
    display: flex;
  justify-content: center;
  align-items: center;
  height: 24vh;
}
.page8-main {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 95%;
    backdrop-filter: blur(21px) saturate(200%);
    -webkit-backdrop-filter: blur(21px) saturate(200%);
    background-color: rgba(3, 12, 55, 0.593);
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    height: 75%;

}
.page8-image1 {
    width: 56.5px;
    height: 56.5px;
}
.page-8-blood {
    color: #FFF;
    text-align: start;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page8-cantact {
    color: #FFF;
    text-align: start;
    font-family: 'Poppins', sans-serif;
    font-size: 18.582px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page8-numper {
    color: #FFF;
    text-align: center;
    display: flex;
    justify-content: center;
    letter-spacing: 0.1rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    align-items: center;
    border-radius: 9px;
    background: var(--SS, linear-gradient(284deg, #B8C2DA -47.47%, #134FE9 42.98%, #4F0909 154.35%));
    font-size: 21px;
    height: 7vh;
    width: 23vw;
    font-style: normal;
    line-height: normal;
    padding:0.25em 0.5em;
}


@media screen and (max-width:840px) {
    .page8-main {
        display: flex;
        width: 100vw;
        flex-direction: column;
        height: auto;
        gap:2em;
        align-items: center;
        justify-content: space-around;
        padding: 29px;
    }
    .page8-numper {
        color: #FFF;
        text-align: center;
        display: flex;
        justify-content: center;
        letter-spacing: 0.1rem;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        align-items: center;
        border-radius: 9px;
        background: var(--SS, linear-gradient(284deg, #B8C2DA -47.47%, #134FE9 42.98%, #4F0909 154.35%));
        font-size: 32px;
        height: 4rem;
        width: 21rem;
        font-style: normal;
        line-height: normal;
    }
    .page8-image1 {
        width: 74.5px;
        height: 75.5px;
    }
    .page8-container {
        height: auto;
    }
}
@media screen and (max-width:840px) {
    .blood-cantact {
        width: auto;
        padding-inline-start: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
.blood-cantact{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
@media screen and (max-width:540px) {
    .page8-numper{
        font-size: 21px;
    }
}