.login-container{
    display: flex;
    width: 100%;
    height: 88vh;
    justify-content: center;
}
.login-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-container h1{
color: #000;
font-family:'Times New Roman', Times, serif;
font-size: 30.18px;
font-style: normal;
height: 10vh;
font-weight: 400;
display: flex;
text-align: center;
line-height: normal;
align-items: center;
justify-content: center;
line-height: normal;
}
.login-container p{
color: #000;
font-family:'Times New Roman', Times, serif;
font-size: 20.12px;
font-style: normal;
height: 10vh;
font-weight: 400;
display: flex;
text-align: center;
line-height: normal;
align-items: center;
margin: 0;
justify-content: center;
line-height: normal;
}
.form-login{
    display: flex;
    gap:1rem;
    height: 10vh;
    align-items: center;
}
.form-login label{
color: #000;
font-family: Times New Roman;
font-size: 26.827px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.form-login span{
color: #000;
font-family: Times New Roman;
font-size: 26.827px;
font-style: normal;
font-weight: 400;
padding:0.25rem;
line-height: normal;
border-radius: 4.192px;
border: 1px solid #000;
}
.form-login input{
    padding: 0.5rem;
    width: 270px;
    border-radius: 4.192px;
    color: #000000;
    font-family: 'Times New Roman', Times, serif;
    font-size: 21.827px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.2px;
    line-height: normal;
    border: 1px solid #000;
    box-shadow: 0px 0px 0px #000;
}
.form-login input:focus{
    outline: none;
    border-color: rgb(95, 255, 63);
}
.form-login input::placeholder{
color: #767676;
font-size: 21.827px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.login-btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
}
.login-btn button{
    width: 175px;
    color: #FFF;
font-family: 'Poppins';
font-size: 26.827px;
font-style: normal;
font-weight: 500;
line-height: normal;
border: none;
    border-radius: 4.192px;
     background: var(--SOCIAL, linear-gradient(89deg, #820000 0.53%, #FF0505 99.5%), #E6E6E6);
    padding: 0.3rem;
}
@media screen and (max-width:600px) {
    .form-login{
        flex-direction: column;
        height: auto;
    }
}