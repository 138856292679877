.page4-container {
  width: 100%;
  height: 65vh;
  overflow: hidden;
  background-image: url('../images/1.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #8fa0cc;
}
.page4-image1{
    width: 70vw;
    height: 1rem;
}
.tamil-page-4-btn{
    background: linear-gradient(4deg, #820000 2.88%, #E66060 70.04%, #AE0707 96.06%), #E6E6E6;
    color: #FFF;
    font-family: Poppins;
    width: 30vw;
    height: 4rem;
    font-size: 1rem;
    border: none;
}
.page-4-btn{
  background: linear-gradient(4deg, #820000 2.88%, #E66060 70.04%, #AE0707 96.06%), #E6E6E6;
color: #FFF;
font-family: Poppins;
width: 25vw;
height: 3.2rem;
font-size: 1.2rem;
border: none;
border-radius: 2px;
}
.page-4-image-text {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  gap: 2rem;
  padding-right: 2rem;
}
.page4-main{
    border-radius: 117.738px 5.887px 0px 117.738px;
    height: auto;
    background: var(--blue-g, linear-gradient(268deg, #356DFF 0%, #001854 97.38%), #E6E6E6);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tamil-page4-p{
    color: #FFF;
    text-align: start;
    font-family: Poppins;
   font-size: 12px;
    font-style: normal;
    white-space: normal;
    font-weight: 600;
}

.page4-p {
    color: #FFF;
    text-align: center;
    margin: 0;
    font-family: Poppins;
    white-space: normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding-inline: inherit;
}

.page4-main-C{
    display: flex;
    width: 400px;
    height: 186px;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}
.img-cover4 {
  width: auto;
  height: auto;
  display: flex;
    justify-content: center;
    align-items: center;
}
.page4-main-C1{
  display: flex;
  gap: 0.8rem;
  flex-direction: row;
  justify-content: space-between;
}

.page4-main-cont1{
  display: flex;
  padding: 2rem 0rem 2rem 0rem;
  gap: 1.3rem;
   overflow-x: hidden;
  flex-wrap: nowrap;
  flex-direction: row;
}
.page4-p-by{
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  justify-content: flex-start;
  padding-left: 1vw;
   
}
.page4-by {
    text-align: end;
    padding: 1em;
}
.by{
    color: #FFF;
    font-family: Racing Sans One;
    font-size: 1rem;
    font-weight: 400;
}
.page4-by p{
    color: #FFF;
    margin: 0;
    font-family: Racing Sans One;
    font-size: 1rem;
    font-weight: 400;
}
@media screen and (max-width:1280px){
  .page4-main-cont1{
    margin-top: 10em;
  }
}
@media screen and (max-width:1250px) {
  
  .page4-main-cont1{
    padding: 0;
    margin-top: 5em;
  }
}
@media screen and (max-width: 968px) {
  .page4-main-cont1{
    padding: 0;
    margin-top: 10em;
   
  }
  .page4-image1 {
    width: 63vw;
    height: 1rem;
}
.page-4-btn{
  font-size: 0.8rem;
  width: 45vw;
}
.tamil-page-4-btn{
  font-size: 0.8rem;
  width: 44vw;
}
.page4-container{
  height: 54vh;
}
.img-cover4 {
  height: 20vh;
}
}

@media screen and (max-width: 550px) {
  .img-cover4 {
    width: 45%;

  }
  .page4-main-cont1{
    margin-top: 5em;
  }
  .page4-main-C{
    gap: 0;
    justify-content: space-between;
  }
  .page4-main-C {
    
    height: 172px;
    width: 356px;
}
}

.scroller {
  width: 100%;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;

}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 60s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s !important; 
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}










