.loader {
    position: relative;
    width: 54px;
    height: 54px;
    border-radius: 10px;
   
  }
  .pic {
    padding: 3px;
}
.leader-card {
   
        display: flex;
        align-items: center;
        width: 100%;
        padding: 18px;
        gap:16px;
        justify-content: center;
    }
.leader-card-bt-details {
    /* gap: 1px; */
    display: flex;
    flex-direction: column;
    height: 142px;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 86%;
}
  .loader div {
    width: 8%;
    height: 24%;
    background: var(--sqas-q, linear-gradient(1deg, #CA541A 6.87%, rgba(232, 94, 50, 0.68) 57.41%, #EA8765 67.28%, rgba(163, 65, 24, 0.72) 100%));
    position: absolute;
    left: 50%;
    top: 30%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    animation: fade458 1s linear infinite;
  }
  /* membercard-back */
  .membar-card-back{
    width: 500px;
    height: 280px;
    background: #04419D;
    overflow: hidden;
    gap: 21px;
    color: #ffffff;
   
    display: flex;
    justify-content: space-between;
    
}
.leader-card-back {
    width: 500px;
    height: 280px;
    background: #000205;
    overflow: hidden;
    gap: 21px;
    color: #ffffff;
   
    display: flex;
    justify-content: space-between;
}
.card-section-main {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
.signature {
    object-fit: contain;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.25rem;
    margin-top: 170px;
    margin-left: 325px;
    position: absolute;
    width: 135px;
}
.signature p{
    position: absolute;
    color: black;
    font-size: 14px;
    top:65px;
}
.signature img{
    width: 100px;
    height: 100px;
}
p.member-card-back-address-details {
    color: white;
    font-size: 10px;
}
  p.member-card-back-address-details {
    color: white;
    font-size: 12px;
    width: 28.6vw;
}
.member-card-back-title {
    padding: 9px;
    font-size: 22px;
    width: 21vw;
}
span.ref-span :hover {
    background-color: #000;

}
.member-card-bt-back {
    padding: 26px;
    height: 33vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 16vw;
}
.member-card-back-list {
    width: 29vw;
    font-size: 12px;
}
/* .member-card-back-address {
    border: solid 1px black;
} */
p.member-card-back-address-details {
    color: white !important;
    font-size: 12px !important;
}
img.member-card-back-img {
    width: inherit;
    height: inherit;
    /* z-index: 1000; */
    object-position: center;
    object-fit: cover;
}
.member-card-back-div-img {
    width: 15vw;
    height: 28vh;
    /* margin-top: 88px; */
    position: relative;
    top: 115px;
    right: -23px;
}

  
  @keyframes fade458 {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0.25;
    }
  }
  
  .loader .bar1 {
    transform: rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
  }
  
  .loader .bar2 {
    transform: rotate(30deg) translate(0, -130%);
    animation-delay: -1.1s;
  }
  
  .loader .bar3 {
    transform: rotate(60deg) translate(0, -130%);
    animation-delay: -1s;
  }
  
  .loader .bar4 {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.9s;
  }
  
  .loader .bar5 {
    transform: rotate(120deg) translate(0, -130%);
    animation-delay: -0.8s;
  }
  
  .loader .bar6 {
    transform: rotate(150deg) translate(0, -130%);
    animation-delay: -0.7s;
  }
  
  .loader .bar7 {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.6s;
  }
  
  .loader .bar8 {
    transform: rotate(210deg) translate(0, -130%);
    animation-delay: -0.5s;
  }
  
  .loader .bar9 {
    transform: rotate(240deg) translate(0, -130%);
    animation-delay: -0.4s;
  }
  
  .loader .bar10 {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.3s;
  }
  
  .loader .bar11 {
    transform: rotate(300deg) translate(0, -130%);
    animation-delay: -0.2s;
  }
  
  .loader .bar12 {
    transform: rotate(330deg) translate(0, -130%);
    animation-delay: -0.1s;
  }
.referral{
    cursor: pointer;
}
.profile-contain{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color:white;
}
.profile-contain-tp{
    height: 15vh;
    background: linear-gradient(180deg, #A9CBFF 0%, #1865D9 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ref-span {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: blue !important;
    align-items: center;
    justify-content: center;
}
.profile-contain-tp p{
color: #FFF;
font-family: 'Poppins', sans-serif;
font-size: 2rem;
font-style: normal;
font-weight: 400;
padding-inline-end: 4rem;
line-height: normal;
margin: 0;
}
.profile-contain-bt{
    display: flex;
    align-items: flex-start;
    gap:2rem;
    padding:1.5rem;
}
.profile-bt-left{
    display: flex;
    width: 16.5625rem;
    height: 17.4375rem;
    border-radius: 1.1875rem;
    border: 1px solid #000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
}
.profile-bt-left img{
    width: 10em;
    height: 10em;
    border-radius: 50%;
}
.profile-bt-left span{
    display: flex;
    align-items: center;
    gap:0.5em;
    padding:0.5em;
    border-radius: 0.375rem;
    background: #04419D;
}
.profile-bt-left span button{
background-color: transparent;
border: none;
color: #FFF;
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.profile-bt-right{
    width: 49.6875rem;
    padding: 2rem 0;
    height: 25.5625rem;
    border-radius: 1.6875rem;
    background: #04419D;
    justify-content: space-around;
    display: flex;
}
.user-profile-details{
    display: flex;
    flex-direction: column;
    gap:0.5em;
}
.user-profile-details h3{
color: #FFF;
font-family: Poppins;
font-size: 2rem;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.user-profile-details h4{
    color: #FFF;
font-family: Poppins;
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
text-transform: uppercase;
letter-spacing: 1.2px;
}
.user-profile-details p{
color: #FFF;
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-block: 2rem;
}
.user-profile-details span{
background: #3D8BFF;
text-align: center;
padding:0.8em;
 color: #FFF;
font-family: 'Poppins';
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.user-profile-cards{
    display: flex;
    flex-direction: column;
    gap:2rem;
}
:is(.donation-history p ,.ref-code p){
   
    font-family: 'Poppins';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin:0.5em;
}
:is(.donation-history p ){
    color: #04419D;
}
.ref-code{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline-start: 1rem;
}
.ref-code p{
     color: #FFF;
} 
.ref-code span{
color: #000;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
height: 45px;
width: 163px;
font-weight: 400;
line-height: normal;
background-color: #FFF;
text-align: center;

}
.view{
    width: 250px;
    height: 8vh;
    font-family: 'Poppins';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    margin: 0.5em;
    color: #04419D
}
:is(.membership-card-1 ,.leadership-card ,.donation-history ,.view){
    display: flex;
    gap:0.5em;
    padding:0.5em;
    align-items: center;
    border-radius: 0.375rem;
    justify-content: center;
}
:is(.membership-card-1 ,.leadership-card ){
    background: #FFF;
}
.donation-history{
    background: #ffffff;
}
:is(.image-open ,.donion-history){
    width: 100%;
    height: 85vh;
    position: absolute;
    left: 0;
    top: 15vh;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #90bcffa8;

}
.membership-card{
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #90bcffa8;
    margin-top: 92px;
}
:is(.donion-history-popup){
    width: 65vw;
    height: 75vh;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    
}
.edit-image-container {
    width: 37.75rem;
    height: 75vh;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
}
:is(.donion-history-tp,.membership-card-tp){
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 12.5vh;
}
.edit-image-title {
    height: 12.5vh;
    display: flex;
    align-items: center;
    padding-inline: 2rem;
    justify-content: space-between;
}
:is(.donion-history-title ,.membership-card-title){
    display: flex;
    width: 75%;
    align-items: center;
    justify-content:space-around;
   
}
:is(.edit-image-title h1,.donion-history-title h1,.membership-card-title h1){
color: #ffffff;
text-align: center;
font-family: Poppins;
font-size: 1.5rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
:is(.edit-image-title img,.donion-history-title img){
    margin-right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
}
.donation-history-table table{
    overflow-y: scroll;
    height: 57.5vh;
    border-collapse: collapse;
}
.donation-history-table {
    height: 75vh;
    overflow-y: scroll;
    background-color: #04419D;
}
.donation-history-table::-webkit-scrollbar{
    width: 0;
}

.donation-history-table thead th {
color: #FFF;
font-family: Poppins;
font-size: 1.02119rem;
font-style: normal;
width: 25vw;
height: 7.5vh;
font-weight: 400;
line-height: normal;
}
.donation-history-table td {
    background: white;
    color: black;
    border: none;
    padding: 0.5em 1.3em;
}
:is(.serial-Num,.serial-Id-1,.serial-Id-2){
    color: #000;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.serial-Num{
    font-size: 1.02119rem;
    text-align: center;
}
:is(.serial-Id-1,.serial-Id-2){
    font-size: 1.1694rem;
}
.serial-Id-2{
    text-align: center;
}
.edit-name-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    height: 45vh;
}
.edit-name-photo-container{
    display: flex;
    flex-direction: column;
    border: 1px solid #000;
    width: 25rem;
    
    gap: 2rem;
    height: 45vh;
    border-radius: 1.1875rem;
    align-items: center;
    justify-content: space-around;
}
.edit-form img{
    width: 10em; height:10em;
}
.select-btn{
    border-radius: 6px;
    background: #84B4FC;
    padding: 0 2em;
    display: flex;
    align-items: center;
    gap:0.5em;
    border: none;
    cursor: pointer;
}
:is(.select-btn span ,.select-btn p){
    color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
background-color: transparent;
margin: 0;
}
.edit-photo-submit {
    height: 12.5vh;
    display: flex;
    justify-content: flex-end;
    width: 29rem;
    align-items: center;
}
.edit-photo-submit button{
    background: var(--Admin-yellow, #E8B501);
    border: none;
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0.25em 1em;
}
.membership-card-container{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgb(98 67 67);
    padding: 8px;
}
.close1 {
    display: flex;
    width: 43px;
    height: auto;
    
    margin: 1vw;
    align-items: center;
    justify-content: center;
    cursor: pointer;
   
}
.close1 img{
    width: 100%;
}
.popup-profile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: #0000008a;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-profile-refferal {
    width: 58vw;
    height: 40vh;
    background: #fff9f9;
    display: flex;
    align-items: center;
    gap:2vh;
    flex-direction: column;
    border-radius: 5px;
}

button.view {

    height: 5vh;
    background: #ffff6b;
    border: none;
    cursor: pointer;
    border-radius: 0.375rem;
}
.pop-profile-close {
    display: flex;
    width: 94%;
    justify-content: space-between;
    padding-left: 2vw;
    /* padding-right: 2vw; */
}
.membar-card{
    width: 340px;
    height: 188px;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgb(59 81 164) 0%, rgb(255 237 253) 0%, rgb(105 168 209) 0%, rgb(68 83 140) 100%);
    color: #ffffff;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.member-card-top {
    margin-bottom: 6px;
    height: 30%;
    display: flex;
    padding-block: 0.5em;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}
.member-card-border{
    /* border: 1px solid #FFF; */
    display: flex;
    align-items: center;
    justify-content:space-around;
    width: 28.5625rem;
    height: 6.25rem;
}
.member-card-border img{
 
    object-fit: cover;
}
.member-card-top-details p{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0%;  
}
.member-card-top-details{
    display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
margin-top: 14px;
}
:is(.member-card-top-details p ,.member-card-top-details span){
color: #FFF;
text-align: center;
font-family: 'Poppins';
font-size:1rem;
font-style: normal;
/* font-weight: 600; */
line-height: normal;
text-transform: uppercase;
}
.member-card-top-details span{
/* border: 1px solid #FFF; */
padding:0.5em 2em;
}
.log-img {
    width: 15vw;
    height: 100%;
    align-items: center;
    display: flex;
}
.leader-card-border img{
  
    object-fit: cover;
}
.member-card-bt {
    border: 5px solid #ffffffb8;
    display: flex;
    padding-inline: 1em;
    align-items: center;
    justify-content: space-around;
    width: 96%;
    border-radius: 11px;
    padding: 12px;
}
:is(.member-card-bt p,.member-card-bt span){
    color: #FFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.member-card-bt p{
    font-size: 12px;
}
.member-card-bt span{
    font-size: 1.25rem;
}
.member-card-bt img{
    width: 115px;
    height: 115px;
    background: #D9D9D9;
}
.member-card-bt-details-name {
    display: flex;
    align-items: center;
    gap: 0.5em;
    height: 32px;
    width: 280px;
}
.member-card-button{
    height: 8.5vh;
    display: flex;
    width: 30em;
    align-items: flex-end;
    padding: 0.5em 2em;
    justify-content: flex-end;
}
.member-card-button p{
    background: #717171;
    padding: 0.5em 2em;
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;

}


.leadership-card-container {
    background: #000205;
    color: #fff;
    display: flex;
    width: 500px;
    height: 280px;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    /* height: auto; */
}

.leader-card-bt {
    display: flex;
    padding-inline: 1em;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    padding-bottom: 8px;
    border: 4px #ffffffa6 solid;
    width: 96%;
    margin-bottom: 10px;
    border-radius: 7px;
}
.leader-card-border{
    
    display: flex;
    align-items: center;
    justify-content:space-around;
    width: 100%;
    height: 6.25rem;
}

.leader-card-top-details{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

.leader-card-top-details span{
border: 1px solid #FFF;
padding:0.5em 2em;
}
.leader-card-bt{
    display: flex;
    padding-inline: 1em;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    padding-bottom: 8px;
    border: 5px solid #ffffffb8;
    width: 96%;
    margin-bottom: 10px;
    border-radius: 11px;
    
}

.leader-card-bt p{
    font-size: 1rem;
}
.leader-card-bt span{
    font-size: 1rem;
}
.leader-card-bt img{
    width: 115px;
    height: 115px;
    background: #D9D9D9;
}
.leader-card-bt-details-name{
    display: flex;
    align-items: center;
    gap:0.5em;
    height: 27px;
}

.card-section-main {
    display: flex;
    align-items: center;
   
    width: 100%;
    padding: 18px;
}

@media screen and (min-width:1536px) {
    .profile-contain-tp p{
     font-size: 2.5rem;
   }
   .profile-bt-left{
    width: 25.5625rem;
    height: 25.4375rem;
   }
   .profile-bt-left img{
    width: 15em;
    height: 15em;
   }
   .profile-bt-left span button{
font-size: 1.3em;
   }
   .profile-bt-right{
    width: 60.6875rem;
    padding:4rem;
    height: 28.5625rem;

   }
   .user-profile-details h3{
    font-size: 3rem;
    }
    .user-profile-details h4{
     font-size:2rem;
  }
    .user-profile-details p{
    font-size: 1.75rem;
    }
    .user-profile-details span{
    
    padding:1em;
    font-size: 1.5rem;

    }
   
    .ref-code span{
        font-size: 1.8em;
    }
    :is(.edit-image-title img,.donion-history-title img) {
        margin-right: 1rem;
        width: 2.5rem;
        height: 4rem;
    }
   
}
@media screen and (min-width:1280px) {
    .profile-bt-left{
        width: 22.5625rem;
        height: 22.4375rem;
        background-color: #04419d;
       }
       .profile-bt-left img{
        width: 12.5em;
        height: 12.5em;
       }
       .profile-bt-left span button{
    font-size: 1.2em;
       }
       :is(.edit-image-title img,.donion-history-title img) {
        margin-right: 1rem;
        width: 2.5rem;
        height: 4rem;
    }
}
@media screen and (max-width: 998px) {
    .profile-contain-bt{
        flex-direction: column;
        align-items: center;
    }
    .profile-bt-right {
        width: 45.6875rem;
    }

    .profile-bt-left {
        width: 20.5625rem;
        height: 20.4375rem;
    }
    .profile-bt-left img {
        width: 12.5em;
        height: 12.5em;
      
    }
    .member-card-button{
        width: 300px;
    }
    :is(.image-open ,.donion-history){
        top: 10vh;
        height: 90vh;
    }
    .profile-bt-left span button{
        font-size: 1.3em;
    }
    .profile-contain-tp{
        height: 10vh;
    }
    .donion-history{
        top:10vh;
        height: 90vh;
    }
    .edit-image-container{
        height: 60vh;
        width: 45.75rem;
    }
    :is(.edit-name-photo-container){
        height: 35vh;
        width: 30rem;
    }
    .edit-name-photo{
        height: 35vh;
    }
    .edit-photo-submit{
     height: 10.5vh;
     width: 37rem;
    }
    :is(.select-btn span ,.select-btn p, .edit-photo-submit button){
        font-size: 24px;
    }
    .edit-form img{
        width: 15em;
        height: 15em;
    }
    .donion-history-tp{
     height: 10vh;
    }
    .donion-history-popup {
        width: 90vw;
        height: 60vh;
    }
    .serial-Num {
        font-size: 1.52119rem;
    }
    :is(.serial-Id-1,.serial-Id-2,.donation-history-table thead th) {
        font-size: 1.25694rem;
    }
    :is(.edit-image-title img,.donion-history-title img){
        margin-right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
    }
    .membership-card-container{
        height: auto;
    }
    .membar-card{
        height: 300px;
    }
}
@media screen and (max-width: 600px) {
    .signature {
        margin-top: 175px;
        margin-left: 340px;
    }
    .profile-contain{
        height: auto;
    }
    .leader-card {
        flex-direction: column;
    }
    .profile-contain-tp p{
        font-size: 12px;
    }
    .edit-image-container {
        height: 70vh;
        width: 31.75rem;
    }
    .edit-form img {
        width: 10em;
        height: 10em;
    }
    .edit-name-photo {
        height: 45vh;
    }
    .edit-photo-submit {
        width: 28rem;
    }
    :is(.edit-name-photo-container) {
        height: 45vh;
        width: 25rem;
    }
    .donion-history-popup {
        width: 90vw;
        height: 80vh;
        border-radius: 0.5em;
    }
    :is(.serial-Id-1,.serial-Id-2,.donation-history-table thead th) {
        font-size: .95694rem;
    }
    .serial-Num {
        font-size: 1.22119rem;
    }
    .profile-bt-right{
        height: auto;
        width: 28.6875rem;
        flex-direction: column;
        padding:2rem;
        gap:2rem;
    }
    /* :is(.membership-card ,.leadership-card ,.donation-history ){
        width: 20rem;
    } */
    .user-profile-cards{
        align-items: center;
    }
    .user-profile-details{
        align-items: center;
    }
    .donion-history-title{
        width: 70%;
    }
    .donion-history {
        height: max(90vh ,165vh);
    }
}
@media screen and (max-width: 450px){
    .member-card-bt {
        
        padding: 1px;
    }
    .member-card-bt-details-name {
        
        gap: 1px;
       
        width: 231px;
    }
    .profile-bt-right {
        width: 18.6875rem;
    }
    .donion-history-title{
        width: 95%;
    }
    .member-card-border{
        width: 20.5625rem;
    }
    .leader-card {
        flex-direction: column;
    }
        .membar-card-back, .leader-card-back {
        width: 100%;
        height: auto;

    }
    .leadership-card-container {
        width: 100%;
    }
    .card-section-main {
        width: 100%;
    }
  
    .member-card-back-div-img {
        width: 48vw;
        height: 20vh;
        /* margin-top: 88px; */
        position: relative;
        top: 169px;
        right: -60px;
    }
    .member-card-back-list {
        width: 71vw;
        font-size: 10px;
    }
    .member-card-back-title {
        padding: 9px;
        font-size: 23px;
        width: 67vw;
    }
    p.member-card-back-address-details {
        width: 59.6vw;
    }
    :is(.leader-card-bt img,.member-card-bt img ){
        width: 80px;
        height: 80px;
        background: #D9D9D9;
    }
    :is(.leader-card-bt span,.member-card-bt span) {
        font-size: 1rem;
    }
    .membership-card-container{
        height: auto;
        width: 100%;
    }
    :is(.membership-card ,.leadership-card ,.donation-history ){
        width: 100%;
    }
    .serial-Num {
        font-size: 1.02119rem;
    }
    .edit-image-container {
        width: 22.75rem;
        border-radius: 8px;
        justify-content: space-between;
    }
    :is(.edit-image-title h1,.donion-history-title h1){
        font-size: 1.3em;
    }
    :is(.edit-name-photo-container){
        width: 21em;
    }
    :is(.select-btn span ,.select-btn p, .edit-photo-submit button) {
        font-size: 21px;
    }
    .edit-photo-submit {
        width: 22rem;
    }
    .signature {
        margin-left: 280px;
    }
}
  /* ... (other responsive styles) ... */


@media screen and (max-width: 990px) {

  /* ... (other responsive styles) ... */
  .membar-card-back, .leader-card-back {
    height: auto;
    padding: 0;
  }
  .member-card-back-title {
  
    font-size: 20px;
    width: 65vw;
}
.leader-card {
    flex-direction: column;
}
.member-card-back-list {
    width: 54vw;
    font-size: 12px;
}
.member-card-bt-back {
    height: auto;
}
.member-card-back-div-img {
    width: 23vw;
    height: 20vh;
    /* margin-top: 88px; */
    position: relative;
    top: 115px;
    right: -4px;
}
.member-card-bt-back {


    padding-left: 3rem;
    padding-top: 10px;
}
.card-section-main {
    flex-direction: column;
}

}

