.page7-petition-container {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), url('../images/page5-bg.png') lightgray 50% / cover no-repeat;
    width: 100%;
    height: auto;
  }
  .page7-image1 {
    width: 77vw;
    height: 1rem;
}
  
  .page7-petition-text {
    display: flex;
    justify-content: center;
    height: auto;
    align-items: center;
}
.page7-petition-text-p{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    width: 75vw;
    font-style: normal;
    font-weight: 400;

}
.page7-petition-tamil-p{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    width: 70vw;
    font-style: normal;
    font-weight: 400;  
}
.petition-inputs {
    border-radius: 8px;
    background: #033876;
    flex-direction: column;
    display: flex;
     gap: 26px;
    justify-content: space-evenly;
    width: auto;
    padding: 2rem;
    height: auto;
   
}
.page7-input-container {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    justify-content: center;
    height: auto;
}
.page7-submit {
    display: flex;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.page-7-image-text {
    display: flex;
    padding-top: 1rem;
    align-items: center;
    justify-content: space-around;
    height: auto;
}
.petition-submit-btn{
    border-radius: 5.275px;
    /* height: 3rem; */
    color: #FFF;
    cursor: pointer;
    padding: 10px 45px 10px 45px;
    width: 170px;
    border: none;
    font-family: Poppins;
    font-size: 17.145px;
    font-style: normal;
    line-height: normal;
    background: linear-gradient(270deg, #820000 1.91%, #FF0505 99.81%), #E6E6E6;
    
}
.petition-in {
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.petition-in-tamil{
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    width: 91vw;
    margin-bottom: 12px;
}

.petition-input-cont {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding-top: 1rem;
}
.input-name-petition{
    background: #033876;
    text-align: center;

    color: #FFF;
    width: 30vw;
    border: 1px solid #FFF;
    height: 3.5rem;
}
.input-contact-petition{
    background: #033876;
    border-top: none;
    width: 30vw;
    border-bottom: 1px solid #FFF;
    color: #FFF;
    border-left: none;
    border-right: none;
    height: 2.5rem;
}
.input-address-petition{
    background: #033876;
    width: 30vw;
    color: #FFF;
    border: 1px solid #FFF;
    margin-top: 1rem;
    height: 7rem;
    resize: none;
}
.petition-in-c{
    display: flex;
    align-items: center;
    width: 23rem;
    justify-content: space-between;
    flex-direction: row;
}
.input-audio-petition{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    width: 30vw;
    align-items: center;
    gap: 2rem;
}
.file-upload-petition{
 cursor: pointer;
    text-align: center;
    color: #FFF;
    padding-block: 0.5rem;
    width: 24vw;
    font-family: Poppins;
    font-size: 18.582px;
    font-style: normal;
 
}
.petition-7-btn {
    color: #FFF;
    font-family:Noto Serif;
    /* padding: 5px 29px 0px 56px; */
    font-size: 16px;
    height: 7vh;
    display: flex;
    width: 25vw;
    border: none;
    font-weight: 500;
    background: linear-gradient(4deg, #820000 2.88%, #E66060 70.04%, #AE0707 96.06%), #E6E6E6;
    /* line-height: normal; */
    gap: 2px;
    align-items: center;
    justify-content: center;
}
.petition-7-btn-tamil {
    display: flex;
    width: 27.1vw;
    padding: 10px;
    height: auto;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 2px;
    font-size: 1.2vw;
    background: linear-gradient(4deg, #820000 2.88%, #E66060 70.04%, #AE0707 96.06%), #E6E6E6;
    margin-inline-start: 1vw;
    margin-inline-end: 1vw;
}
.petition-type{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 18.582px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.btn-petition-audeo{
    height: 2rem;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 18.582px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 8rem;
}

.petition-select {
    background: #033876;
    color: #FFF;
    border: 0.581px solid #FFF;
    font-family: Poppins;
    width: 10rem;
    padding-left: 0.5rem;
    height: 2.5rem;
    padding-right: 0.5rem;
    text-align: center;
    font-size: 18.582px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.equal{
    padding-right: 2rem;
    color: #FFF;
}

@media screen and (max-width:680px) {
    .page7-petition-container {
        height: auto;
    }
    .petition-input-cont{
        flex-direction: column;
    }
    .file-upload-petition{
        width: 60vw;
        padding-inline: 0;
    }
    .petition-in-c{   
        width: 65vw;
    }
    .input-name-petition{
        width: 60vw;
    }
    .input-contact-petition{
        width: 60vw;  
    }
    .input-address-petition{
        width: 60vw;   
    }
    .petition-inputs {
        border-radius: 19.573px;
        background: #033876;
        padding-left: 2rem;
        flex-direction: column;
        padding-bottom: 1rem;
        display: flex;
        align-items: start;
        gap: 1rem;
        padding-top: 2rem;
        justify-content: space-evenly;
        padding-right: 0rem;
        width: 72vw;
        height: auto;
    }
    .page7-submit {
        display: flex;
        height: 10vh;
        width: 71vw;
        align-items: center;
        justify-content: center;
    }
    .page7-petition-text-p {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 1rem;
        padding: 0px;
        width: auto;
        font-style: normal;
        font-weight: 400;
    }
 
    .petition-7-btn {
        color: #FFF;
        font-family: Poppins;
        font-size: 2.8vw;
        border: none;
        font-weight: 500;
        width: auto;
        padding: 10px;
        height: auto;
        background: linear-gradient(160deg, #356DFF 14.5%, #0030AD 79.56%), #E6E6E6;
        line-height: normal;
    }
    .petition-7-btn-tamil{
        width: 39vw;
        font-size: 2.8vw;
    }
    .page-4-image-text {
        display: flex;
        justify-content: space-between;
        padding-top: 2rem;
        align-items: center;
        gap: 0px;
        padding-right: 1rem;
    }
    .page7-petition-text{
        padding: 1rem;
        height: 20vh;
    }
    .page7-petition-tamil-p{
        width: auto;
        font-size: 2.8vw;
    }
    .equal{
        display: none;
    }
  
}
@media screen and (max-width: 898px) {
    .page7-petition-container{
        height: auto;
    }
    .petition-7-btn{
        width: 43vw;
    }
 
}
@media screen and (max-width: 1054px) {
       .petition-in-c{
        width: 18rem;
    }
    
}
@media screen and (max-width: 1024px) {
    .page7-input-container {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

}
@media screen and (max-width:450px){
    .petition-7-btn {
        width: 53vw;
    }
    .page-7-image-text {
        height: 15vh;
        padding: 0;
    }
    .petition-input-cont {
        padding-top: 0vh;
        align-items: flex-start;
    }
    .input-name-petition{
        height: 43px;
        width: 67vw;
    }
    .petition-inputs{
    width: 86vw;
    height: auto;
    }
    .petition-in-c{
        height: 5vh;
    }
    .input-address-petition {
        width: 69vw;
    }
    .input-audio-petition {
        width: 0vh;
        padding-top: 3vh;
    }
    .file-upload-petition{
        padding-block: 0vh
    }
    .page7-input-container{
        padding-top: 2vh;
    }
    .petition-submit-btn {
        width: 46vw;
    }
    .success-popup {
        width: 57wh;
        height: 15vh;
    }
    
}
/* Add this CSS to your stylesheet */
.success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 36vw;
    height: 30vh;
    align-items: center;
    justify-content: space-around;
    font-family: Poppins;
    color: black;
}
  
  .success-popup p {
    font-size: 18px;
    margin: 0;
  }
  
  /* You can customize the styling further to match your design preferences */
  .close-button {
    width: 11vw;
    height: 4vh;
    background-color: #7be455;
    color: white;
    border: none;
}
