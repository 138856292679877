.about-image {
    width: 219px;
    height: 225.952px;
    flex-shrink: 0;
}
.about-main-cont {
    display: flex;
    align-items: center;
    height: 60vh;
    justify-content: space-around;
    background: #E9E9E9;
}
.heading-about {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.why-content-tamil{
    font-size: 2rem;
}
.heading-about-tamil{
    font-size: 1.5rem;
}
.content-why-tamil{
    font-size: 1rem;
    text-align: justify;
    width: 59vw;
}
.founting-price-tamil{
    font-size: 1rem;
    text-align: justify;

    width: 53vw;
}

.providing-text-tamil {
    width: 58vw;
}
.founding-content-tamil {
    font-size: 1.5rem;
}
.integral-human-tamil{
    font-size: 1.2rem;
}
.achiving-head-tamil {
    font-size: 1.2rem;
}
.about-text-cont {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    letter-spacing: 1.5px;
    font-weight: 400;
    line-height: normal;
}
.about-main2-cont {
    display: flex;
    padding: 2rem;
    justify-content: space-around;
    align-items: center;
}
.content-why{
    width: 55vw;
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170.5%;
    letter-spacing: 1.6px;
}
.why-content {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.integral-human {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 170.5%;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}
.founting-price {
    color: #000;
    font-family: Poppins;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    width: 55vw;
    font-weight: 400;
    line-height: 170.5%;
    letter-spacing: 1.6px;
}
.founding-content {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 170.5%;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}
.providing-text {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    width: 55vw;
    font-weight: 400;
    line-height: 170.5%;
    letter-spacing: 2.4px;
}
.achiving-head {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 170.5%;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}
@media screen and (max-width: 938px) {
    .about-main-cont{
       height: auto;
       padding: 1rem;
       align-items: center;
        flex-direction: column-reverse;
    }
    .heading-about{
        text-align: center;
    }
    .founting-price{
        width: auto;
    }
    .about-main2-cont{
        padding: 1rem;
        align-items: start;
        flex-direction: column;
    }
   
    .content-why{
        width: auto;
    }
    .providing-text{
        width: auto;
    }
    .about-text-cont{
        line-height: 1.8;
        font-size: 1.2rem;
        text-align: center;
    }
    .content-why-tamil {
        font-size: 1rem;
        width: auto;
    }
    .founting-price-tamil {
        font-size: 1rem;
        width: auto;
    }
    .providing-text-tamil {
        width: auto;
    }
 
}

@media screen and (max-width: 518px) {
    .about-image{
        width: 10rem;
        height: 10rem;
    }
    .providing-text-tamil {
        width: auto;
    }
    .about-text-cont{
        font-size: 1rem;
    }
    .why-content{
        font-size: 1.5rem;
    }
    .integral-human{
        font-size: 1rem;
    }
    .founding-content{
        font-size: 1rem;
        text-align: start;
    }
    .content-why{
        letter-spacing: 1px;
        font-size: 0.8rem;
    }
    .founting-price{
        letter-spacing: 1px;
        font-size: 0.8rem;
    }
    .achiving-head{
        font-size: 1rem;
    }
    .providing-text{
        letter-spacing: 1px;
        font-size: 1rem;
    }
    .content-why-tamil {
        font-size: 1rem;
        width: auto;
    }
    .founting-price-tamil {
        font-size: 1rem;
        width: auto;
    }
}