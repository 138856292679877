.JionMembership-contain{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.JionFrom-content-date {
  display: flex;
}
.JionFrom-contain{
  display: flex;
  flex-direction: column;
  gap:2.5em;
  padding-inline:2em;
  padding-block-end: 4em;
}
.dist{
  margin-left: -1vw;
}
.loads{
  color: #11e92d;
}
.sub-3 {
  display: flex;
  gap: 2vw;
  justify-content: center;
  align-items: center;
  width: 31vw;
}
.sub-2 {
  display: flex;
  gap: 1vw;
  flex-direction: column;
  font-size: 12px;
  /* width: 18vw; */
}
.join-sub {
  display: flex;
  align-items: center;
  margin-top: -4vh;
}
.preview img {
  width: 4vw;
  height: auto;
}
.jion-cont {
display: flex;
align-items: center;
gap: 1rem;
justify-content: space-between;
width: 15vw;
}
:is(.JionFrom-title-tamil ,.JionFrom-title-english){
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

:is(.JionFrom-title-english h1,.JionFrom-title-tamil h1) {
color: #000;
font-family: 'Poppins',sans-serif;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
margin: 0.3em;
}
.JionFrom-title-english h1{
  font-size: 2rem;
}
.JionFrom-title-tamil h1{
  font-size: 1.8em;  
}

:is(.JionFrom-title-english p,.JionFrom-title-tamil p){
color: #000;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0.3em;
}
.JionFrom-title-english p{
  font-size: 0.95531rem;
}
.JionFrom-title-tamil p{
  font-size: 0.8rem;
  text-align: center;
}
.JionFrom-content{
  display: flex;
  gap:2em;
  width: 100%;
}
.JionFrom-content-right{
  width: 100%;
}
.JionFrom-content-left{
  width: 100%;
}
.JionFrom-content-inputs {
width: 45vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.JionFrom-content-inputs-1 {
width: 100%;
display: flex;
align-items: center;
gap: 1em;
justify-content: space-between;
}
.JionFrom-content-right form{
  display: flex;
  flex-direction: column;
  gap:2em;
}
.JionFrom-content-inputs-1 label{
width: 150px;
color: #000;
font-family: 'Poppins';
font-size: 0.95531rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.JionFrom-content-inputs label{
  width: 150px;
  color: #000;
font-family: 'Poppins';
font-size: 0.95531rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
:is(.JionFrom-content-inputs input ,.JionFrom-content-inputs select){
  
  padding: 0.5em;
  border-radius: 0.14331rem;
  border: 1px solid #000;
  text-align: center;
  
}
:is(.JionFrom-content-inputs-1 input ,.JionFrom-content-inputs-1 select){
margin-right: 6em;
padding: 0.5em;
border-radius: 0.14331rem;
border: 1px solid #000;

}
.JionFrom-content-inputs-1 input{
margin-right: 3em;
width: 18.15344rem;
}
.JionFrom-content-inputs-1 select{
margin-right: 6em;

}
.JionFrom-content-inputs-1 input:focus{
outline: none;
border: 2px solid #042069;
}
.JionFrom-content-inputs input {
 margin-left: 1rem;
  width: 24vw;
  height: 32px;
  padding: 0.5em;
}

.JionFrom-content-inputs input:focus{
 outline: none;
 border: 2px solid #042069;
}
.select-box {
position: relative;
display: block;
padding: 0.5rem;
margin-left: 2rem;
width: 16rem;
font-family: "Open Sans", "Helvetica Neue", "Segoe UI", "Calibri", "Arial", sans-serif;
font-size: 16px;
color: #60666d;
border-radius: 0.14331rem;
border: 0.764px solid #000;
margin-right: 5em;
height: 32px;
}
.select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
}
.select-box__current:focus + .select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
          animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}
.select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(180deg);
}
.select-box__icon {
  position: absolute;
  top: 15px;
  right: 15px;
  transform: translateY(-50%);
  width:1em;
  transition: 0.2s ease;
}
.select-box__value {
  display: flex;
}
.select-box__input {
  display: none;
}
.select-box__input:checked + .select-box__input-text {
  display: block;
}
.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  background-color: #fff;
}
.select-box__list {
  position: absolute;
  width: 100%;
  padding: 0;
  z-index: 1;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
          animation-name: HideList;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
          animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
 
}
.select-box__option {
  display: block;
  padding: 15px;
  background-color: #a4b4ff;
  width: 14rem;
  
}
.select-box__option:hover, .select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

.JionFrom-content-inputs textarea {
  width: 18rem;
  height: auto;
  margin-right:5em;
  resize: none;
  margin-left: 1rem;
  border: 1px solid #000000;
}
.JionFrom-content-inputs textarea:focus{
  outline: none;
  border: 2px solid #042069;
}
.Address {
  display: flex;
  gap: 1em;
  flex-direction: column;
 
  margin-top: 32px;
  flex-wrap: wrap;
}
.JionFrom-content-inputs textarea {
width: 18rem;
height: auto;
margin-right: 5em;
resize: none;
border: 1px solid #000000;
margin-left: 0vw;

}

.required{
color: red;

}
.Address-join{
display: flex;
flex-direction: column;
}
.ad-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  height: 11vh;
}


.Upload-content-inputs{
display: flex;
align-items: center;
gap:1em;
}
.UploadAdharaCard{
display: flex;
flex-direction: column;
gap:0.5em;
width: 15vw;
align-items: flex-start;
}
.text-area-address {

  position: relative;
  display: block;
  padding: 0.5rem;
  margin-left: 1rem;
  width: 17rem;
  font-family: "Open Sans", "Helvetica Neue", "Segoe UI", "Calibri", "Arial", sans-serif;
  font-size: 16px;
  color: #60666d;
  border-radius: 0.14331rem;
  border: 0.764px solid #000;
  margin-right: 5em;
  height: 41px;

}
.UploadAdharaCard p{
color: #000;
font-family: 'Poppins';
font-size: 0.95531rem;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.UploadAdharaCard span{
color: #1A4EB4;
font-family: Poppins;
font-size: 0.57319rem;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
text-align: center;
}
.Upload-adhar-btn{
padding: 0.5em 2em;
display: flex;
align-items: center;
justify-content: center;
border-radius: 0.38213rem;
background: var(--button, linear-gradient(180deg, #697CAD 0%, #143589 100%));
}
.Upload-adhar-btn label{
margin: 0;
color: #FFF;
font-family: Poppins;
font-size: 0.95531rem;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}
.JoinNow{
width: 100%;
display: flex;

text-align: center;
align-items: center;
justify-content: center;
}
:is(.JoinNow button:disabled,.JoinNow button:not(:disabled)){
color: #FFF;
font-family: Poppins;
font-size: 1.24194rem;
font-style: normal;
font-weight: 500;
padding:0.5em 1em;
width: 150px;
border: none;
line-height: normal;
text-transform: uppercase;
border-radius: 0.38213rem;

}
.JoinNow button:disabled{
background-color: rgb(0, 0, 0);
}
.JoinNow button:not(:disabled){
background: var(--SOCIAL, linear-gradient(89deg, #820000 0.53%, #FF0505 99.5%), #E6E6E6);
width: 158px;
}
.uploaded-btn{
display: flex;
align-items: center;
gap:1em;
}
.uploaded-btn p{
color: #05642B;
font-family: Poppins;
font-size: 0.86875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}

.uploaded-btn span{
color: #0066AF;
font-family: Poppins;
font-size: 0.86875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.join-preview-btns{
  display: flex;
  justify-content: space-evenly;

}
button.preview-back {
  width: 14vw;
  height: 5vh;
  background: #e1f40a;
  border: none;
  color: rgb(26, 27, 4);
}
button.preview-submit {
  width: 14vw;
  height: 5vh;
  background: #00cf00;
  border: none;
  color: white;
}
.popup-overlay1 {
  background: #ebe8c675;
  
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003;
  position: fixed;
}
/* popup */
.popup-overlay {
  position: fixed;
  
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.gallery-popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  animation: myAnim 2s ease 0s 1 normal forwards;
  width: 73vw;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  padding: 57px;
}
.gallery-popup p {
  font-size: 22px;
}
.p2{
  font-size: 14px;
    color: #051ea9;
}

.popup-yes {
  background: #4caf50;
  color: #fff;
  padding: 20px 60px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 8px;
}

.popup-yes:hover {
  background: #45a049;
}

@keyframes myAnim {
	0% {
		animation-timing-function: ease-out;
		transform: scale(1);
		transform-origin: center center;
	}

	10% {
		animation-timing-function: ease-in;
		transform: scale(0.91);
	}

	17% {
		animation-timing-function: ease-out;
		transform: scale(0.98);
	}

	33% {
		animation-timing-function: ease-in;
		transform: scale(0.87);
	}

	45% {
		animation-timing-function: ease-out;
		transform: scale(1);
	}
}
/* popup-end */
img.jionMember-coverImg{
width: 100%;
}
@media screen and (max-width: 1024px) {
.JionFrom-content-left {
  width: 70%;
}
img.jionMember-coverImg{
  width: 100%;
}
}

@-webkit-keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

@media screen and (max-width: 820px) {
  .JionFrom-content{
    flex-direction: column;
    align-items: center;
  }
  .JionFrom-content-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .JionFrom-content-inputs{
    width: 75vw;
  }
  img.jionMember-coverImg {
    width: 100%;
    height: 496px;
    object-fit: cover;
}
.jion-cont{
  width: 24vw;

}
.JionFrom-content-inputs input {
  width: 43vw;
}

}
@media screen and (max-width:450px) {
.JionFrom-content-inputs{
  flex-direction: column;
  gap:1em;
  align-items: flex-start;
}
.preview img {
  width: 21vw;
  height: auto;
}
.ad-data {
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
}
.JionFrom-content-inputs input {
  width: 64vw;
}
.JionFrom-content-left{
  width: 100%;
}
:is(.Upload-content-inputs,.JionFrom-content){
  flex-direction: column;
}
.jionMember-coverImg{
  width: 310px !important;
}
:is(.select-box,.JionFrom-content-inputs input,.Address){
  margin: 0;
}
.jion-cont p{
  display: none;
}
.jion-cont{
  flex-direction: column;
  align-items: flex-start;
}
.JoinNow p{
  margin: 0;
}

}

@media screen and (max-width: 290px) {
.jionMember-coverImg {
  width: 228px !important;
}
.JionFrom-content-inputs textarea{
margin: 0;
width: 14rem;
height: auto;
}
.preview img {
  width: 21vw;
  height: auto;
}
.JionFrom-content-inputs input {
width: 80vw;
}
.data5 select{
margin: 0;
}
.JionFrom-content-inputs {
width: 89vw;
}
.data5 select{
width: 85vw;
}
}