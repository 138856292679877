.tamil20-font2{
    font-size: 20px;
  }
  .tamil18-font2{
    font-size: 18px;
  }
  .tamil30-font2{
    font-size: 30px;
  }
  .tamil12-font2{
    font-size: 12px;
  }
  .container-2 {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: linear-gradient(60deg, #01084D 10.21%, #6565C7 41.24%, #5050B5 44.66%, #4242AB 47.39%);
  }
  .part1-2{
   
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .text-box{
    height: 2vh;
  }

.part2-2{
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    
}
.part3-2{
    width: 100%;
    height: 20vh;
    display: flex;
   align-items: center;
   justify-content: end;
    
    
}
.part1-2 img {
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    width: 22vw;
    height: auto;
    mix-blend-mode: screen;

  }
  
.box-2{
display: flex;
width: 40vw;
height: auto;
padding: 6vh;
overflow: hidden;
border-radius: 4.73px;
border: 0px solid #FFF;
background: linear-gradient(252deg, #000 -10.35%, #4A4848 2.44%, #000 100%);
}
.part2-2 p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    display: block;
    font-weight: 500;
    line-height: normal;
    font-size: 1.4vw;
   
   
}
.part1-2 p {
  font-size: 2.5vw;
  color: #FFF;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-inline-end: 7vw;
  font-family: 'Times New Roman', Times, serif;
    
    
}


.right-logo-2 img {
    max-width: 50vw;
    width: 33vw;
    height: auto;
 }
 .part3-2 img {
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    width: 26vw;
    height: auto;
    mix-blend-mode: screen;
  }

@media screen and (max-width: 820px) {
    .part1-2 p {
        font-size: 4vw;
    }
    .part1-2 {
        height: auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .box-2{
        width: 70vw;
    }
    .container-2{
        height: auto;
    }
    .part2-2{
        height: auto;
        flex-direction: column-reverse;
    }
    .right-logo-2{
        width: 30vw;
        height: auto;
        display: flex;
        justify-content: center;
    }
    .box-2{
        padding: 4vw;
       
    }
    .part2-2 p{
        font-size: 2.6vw;
    }
    .part3-2 img {
        width: 41vw;
    }
    .right-logo-2 img{
        width: 62vw;
    }
    .part1-2 img{
        width: 34vw;
    }

}
@media screen and (max-width: 476px) {
    /* Styles for screens with a minimum width of 768px */
   
    .part1-2 img {
        display: none;
        
    }
    .part1-2 {
        width: 100vw;
        height: 7vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
    }
    .part2-2 {
        flex-direction: column-reverse;
        height: auto;
        justify-content: space-evenly;
        padding-bottom: 4vh;
    }
    .right-logo-2 {
        width: 44vw;
        height: 22vh;
    }
    .right-logo-2 img {
       
        width: 45vw;
       
    }
    .part1-2 p {
        margin-inline-end: 0vh;
        font-size: 5.5vw;
    }
    .box-2{
        font-size: 20px;
        padding: 6vw;
        width: 75vw;
    }
    .part2-2 p{
        font-size: 14px;
    }
    .part3-2{
        display: none;
    }
    .container-2 {
        height: auto;
        padding-bottom: 3vh;
    }

}
  
  

/* p {
    width: 70%;
    font-style: normal;
    font-size: 10px;
    display: block;
    text-align: center;
} */

.char {


    display: inline-block;
}

@keyframes an {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    to {
        opacity: 1;
        transform: perspective(500px) translate3d(0, 0, 0);
    }
}


